export interface TableRow {
    isUserHotel: boolean;
    isMedian: boolean;
    hotelId: number | string;
    hotelName: string;
    price: number | string;
    priceString: string;
    name: string;
    rank: number | null;
    legendColor: string;
}

export enum FirstColumnType {
    RANK = 'rank',
    LEGEND = 'legend'
}
