var render = function () {
var _obj;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container"},[_c('span',{class:{
            'text before': true,
            'active': !_vm.value
        }},[_c('span',[_vm._v(_vm._s(_vm.inactiveText))])]),_c('label',{class:( _obj = {
            'switch': true
        }, _obj['switch--' + _vm.appearance] = !!_vm.appearance, _obj )},[_c('input',{attrs:{"type":"checkbox","disabled":_vm.isDisabled},domProps:{"checked":_vm.value},on:{"input":function($event){return _vm.handleChange($event.target.checked)}}}),_c('span',{staticClass:"slider round",class:{ 'disabled': _vm.isDisabled }})]),_c('span',{class:{
            'text after': true,
            'active': _vm.value
        }},[_c('span',[_vm._v(_vm._s(_vm.activeText))])])])}
var staticRenderFns = []

export { render, staticRenderFns }