











import { Component, Vue, Prop } from 'vue-property-decorator';
import { Inject } from 'inversify-props';
// @ts-ignore
import CustomSelect, { Item } from '@/modules/common/components/ui-kit/custom-select.vue';
import RoomTypeModel from '@/modules/room-types/models/room-type.model';
import RoomTypesService, { RoomTypesServiceS } from '@/modules/room-types/room-types.service';
import RatesService, { RatesServiceS } from '@/modules/rates/rates.service';
import ANY_ROOM_TYPE from '@/modules/room-types/constants/any-room-type.constant';
import UserService, { UserServiceS } from '@/modules/user/user.service';
import ClusterService, { ClusterServiceS } from '@/modules/cluster/cluster.service';

@Component({
    components: { CustomSelect },
})
export default class RoomTypeFilter extends Vue {
    @Inject(RatesServiceS) ratesService!: RatesService;
    @Inject(RoomTypesServiceS) private roomTypesService!: RoomTypesService;
    @Inject(UserServiceS) userService!: UserService;
    @Inject(ClusterServiceS) clusterService!: ClusterService;

    @Prop({
        required: false,
        type: Boolean,
        default: false,
    })
    disable!: boolean;

    get roomType() {
        return this.ratesService.storeState.settings.roomTypeId;
    }

    set roomType(value) {
        if (value) {
            this.ratesService.storeState.settings.roomTypeId = value;
        }
    }

    currentRoomType(roomTypeId: number) {
        const roomType = this.roomTypesService.roomName(roomTypeId);
        if (!roomType) {
            return 'Any';
        }
        return roomType;
    }

    get roomTypeItems(): Item[] {
        if (!this.roomTypesService.rooms) {
            return [{
                value: ANY_ROOM_TYPE.id,
                name: ANY_ROOM_TYPE.name,
            }];
        }

        return this.roomTypesService.rooms
            .filter((roomType: RoomTypeModel) => !!roomType.name)
            .map((roomType: RoomTypeModel) => ({
                value: roomType.id,
                name: roomType.name,
            }));
    }
}
