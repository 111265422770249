


























import { Component, Vue, Prop } from 'vue-property-decorator';
import { Inject } from 'inversify-props';
import RatesFiltersService, { RatesFiltersServiceS } from '@/modules/rates/rates-filters.service';
import PRICE_SHOWN from '@/modules/rates/constants/price-shown.constant';
import RatesSettingsModel from '@/modules/rates/models/rates-settings.model';

@Component
export default class TogglePrices extends Vue {
    @Inject(RatesFiltersServiceS) private ratesFiltersService!: RatesFiltersService;

    @Prop({
        type: Boolean,
        default: false,
    })
    isDisabled!: boolean;

    @Prop({
        type: Boolean,
        default: false,
    })
    customModel!: boolean;

    @Prop({
        type: Object,
        default: () => null,
    })
    settings!: RatesSettingsModel | null;

    @Prop({
        type: String,
        default: PRICE_SHOWN.SHOWN,
    })
    value!: PRICE_SHOWN;

    options = [
        { value: PRICE_SHOWN.SHOWN, text: 'Shown' },
        { value: PRICE_SHOWN.NET, text: 'Net' },
        { value: PRICE_SHOWN.TOTAL, text: 'Total' },
    ];

    set model(newValue: PRICE_SHOWN) {
        if (this.customModel) {
            this.$emit('input', newValue);
            return;
        }

        if (this.settings) {
            this.settings!.priceShown = newValue;
            return;
        }

        this.setPriceShown(newValue);
    }

    get model(): PRICE_SHOWN {
        if (this.customModel) {
            return this.value;
        }

        if (this.settings) {
            return this.settings.priceShown;
        }

        return this.ratesFiltersService.storeState.settings.priceShown;
    }

    setPriceShown(price: PRICE_SHOWN) {
        this.ratesFiltersService.togglePrices(price);
    }
}
