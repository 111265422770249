












import RatesDotDropdown from '@/modules/rates/components/calendar/rates-dot-dropdown.vue';
import {
    Component, Vue, Prop,
} from 'vue-property-decorator';
import Day from '@/modules/common/types/day.type';
import RatesDayScanBtn from '@/modules/rates/components/rates-day-scan-btn.vue';

@Component({
    components: {
        RatesDotDropdown,
        RatesDayScanBtn,
    },
})
export default class RatesGraphTooltip extends Vue {
    @Prop({
        required: true,
        type: Number,
    })
    private day!: Day;

    @Prop({
        required: false,
        type: Function,
    })
    private freezeTooltip!: Function;

    handleDotsClick(e: Event) {
        e.stopPropagation();
    }

    handleTooltipClick() {
        this.$emit('click', this.day);
    }
}
