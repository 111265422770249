





















































































































import { Inject } from 'inversify-props';
import { Component, Mixins } from 'vue-property-decorator';
import CompsetDocumentFilter from '@/modules/document-filters/components/compset-type-document-filter.vue';
import CompetitorsFilter from '@/modules/rates/components/actions/competitors-filter.vue';
import LosDocumentFilter from '@/modules/document-filters/components/los-document-filter.vue';
import PosDocumentFilter from '@/modules/document-filters/components/pos-document-filter.vue';
import MealTypeFilter from '@/modules/rates/components/actions/meal-type-filter.vue';
import NumberOfGuestsFilter from '@/modules/rates/components/actions/number-of-guests-filter.vue';
import PriceTypeFilter from '@/modules/rates/components/actions/price-type-filter.vue';
import ProviderTypeFilter from '@/modules/rates/components/actions/provider-type-filter.vue';
import RoomTypeFilter from '@/modules/rates/components/actions/room-type-filter.vue';
import ExcelBtn from '@/modules/common/components/excel-btn.vue';
import CustomSelect from '@/modules/common/components/ui-kit/custom-select.vue';
import CustomMultiSelect from '@/modules/common/components/ui-kit/custom-multi-select.vue';
import RatesAnalysisFiltersService, { RatesAnalysisFiltersServiceS } from '@/modules/rates/rates-analysis-filters.service';
import AnalysisBtn from '@/modules/common/components/ui-kit/analysis-btn.vue';
import RatesFilterItemsMixin from '../../mixins/rates-filters.mixin';

@Component({
    components: {
        CompsetDocumentFilter,
        CompetitorsFilter,
        LosDocumentFilter,
        PosDocumentFilter,
        NumberOfGuestsFilter,
        MealTypeFilter,
        PriceTypeFilter,
        ProviderTypeFilter,
        RoomTypeFilter,
        AnalysisBtn,
        ExcelBtn,
        CustomSelect,
        CustomMultiSelect,
    },
})
export default class RatesActions extends Mixins(RatesFilterItemsMixin) {
    @Inject(RatesAnalysisFiltersServiceS)
    ratesAnalysisFiltersService!: RatesAnalysisFiltersService;

    get isLoading() {
        return this.ratesService.isLoading;
    }

    get currentPath() {
        return this.$route.path;
    }

    get currentRouteName() {
        return this.$route.name!;
    }

    get isCalendarView() {
        const { currentRouteName } = this;

        return !currentRouteName.includes('.table') && !currentRouteName.includes('.graph-');
    }

    get hotels() {
        return this.hotelsService.allHotels;
    }

    get isGraphView() {
        const { currentRouteName } = this;

        return currentRouteName.includes('.graph-');
    }

    get isTableView() {
        const { currentRouteName } = this;

        return currentRouteName.includes('.table');
    }

    get isAnalysisMode() {
        return this.currentRouteName.includes('.analysis');
    }

    get ratesRouteName() {
        return this.currentRouteName
            .split('.day-rate')[0]
            .replace(/\.(analysis|graph-\w+|table)/g, '');
    }

    get calendarRoute() {
        return this.getRouteTo('');
    }

    get tableRoute() {
        return this.getRouteTo('.table');
    }

    getRouteTo(suffix: string) {
        if (this.isAnalysisMode) {
            const routeName = this.currentRouteName.replace(/\.analysis(\..+)/, '.analysis');

            return {
                name: `${routeName}${suffix}`,
                params: this.$route.params,
            };
        }

        return {
            name: `${this.ratesRouteName}${suffix}`,
            params: this.$route.params,
        };
    }

    goToGraphView() {
        this.$router.push({
            name: `${this.ratesRouteName}.graph-compset`,
            params: this.$route.params,
        });
    }
}
