









import { Component, Vue } from 'vue-property-decorator';
import { Inject } from 'inversify-props';
import CustomMultiSelect, { Item } from '@/modules/common/components/ui-kit/custom-multi-select.vue';
import CompsetsService, { CompsetsServiceS } from '@/modules/compsets/compsets.service';
import HotelsService, { HotelsServiceS } from '@/modules/hotels/hotels.service';
import RatesFiltersService, { RatesFiltersServiceS } from '@/modules/rates/rates-filters.service';
import RatesCommonService, { RatesCommonServiceS } from '@/modules/common/modules/rates/rates-common.service';

@Component({
    components: { CustomMultiSelect },
})
export default class CompetitorsFilter extends Vue {
    @Inject(CompsetsServiceS) private compsetsService!: CompsetsService;
    @Inject(HotelsServiceS) private hotelsService!: HotelsService;
    @Inject(RatesFiltersServiceS) private ratesFiltersService!: RatesFiltersService;
    @Inject(RatesCommonServiceS) private ratesCommonService!: RatesCommonService;

    get currentValue() {
        const { competitors } = this.ratesFiltersService;

        if (!competitors) {
            return [];
        }

        return competitors.map(item => ({
            name: this.hotelsService.getHotelName(item),
            value: item,
        })) || [];
    }
    set currentValue(value) {
        this.ratesFiltersService.competitors = value.map(item => item.value);
    }

    get options() {
        const { competitors } = this.compsetsService;
        if (!competitors) {
            return [];
        }
        return competitors.map(val => ({
            value: val,
            name: this.hotelsService.getHotelName(val),
        }));
    }
}
