


















import CustomTooltip from '@/modules/common/components/ui-kit/custom-tooltip.vue';
import { Component, Vue, Prop } from 'vue-property-decorator';

@Component({
    components: {
        CustomTooltip,
    },
})
export default class DisplayCurrencyTooltip extends Vue {
    @Prop({
        type: String,
    })
    side!: 'left' | 'right';
}
