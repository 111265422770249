


































import { Component, Vue } from 'vue-property-decorator';
import { Inject } from 'inversify-props';
import { ChartDataSets, ChartOptions } from 'chart.js';
import RatesGraphTooltip from '@/modules/rates/components/graph/rates-graph-tooltip.vue';
import HotelsService, { HotelsServiceS } from '@/modules/hotels/hotels.service';
import RatesService, { RatesServiceS } from '@/modules/rates/rates.service';
import GraphHotelsLabel from '@/modules/common/components/graph-hotels-label.vue';
import Demand from '@/modules/common/components/ui-kit/demand.vue';
import Occupancy from '@/modules/common/components/ui-kit/occupancy.vue';
import CustomGraph from '@/modules/common/components/ui-kit/custom-graph/graph.vue';
import DocumentFiltersService, { DocumentFiltersServiceS } from '@/modules/document-filters/document-filters.service';
import CompsetsService, { CompsetsServiceS } from '@/modules/compsets/compsets.service';
import UserService, { UserServiceS } from '@/modules/user/user.service';
import CURRENT_HOTEL_GRAPH_COLOR from '@/modules/common/constants/current-hotel-graph-color.constant';
import Day from '@/modules/common/types/day.type';
import RatesFiltersService, { RatesFiltersServiceS } from '@/modules/rates/rates-filters.service';
import setScrollbarPadding from '@/modules/common/filters/scrollbar-padding.filter';
import DayRateTooltipHotel from './day-rate-tooltip-hotel.vue';

export interface IDisabled {
    val: boolean;
    id: number;
}

@Component({
    components: {
        CustomGraph,
        Occupancy,
        Demand,
        RatesGraphTooltip,
        GraphHotelsLabel,
        DayRateTooltipHotel,
    },
})
export default class RatesGraphHotels extends Vue {
    @Inject(RatesServiceS) ratesService!: RatesService;
    @Inject(RatesFiltersServiceS) private ratesFiltersService!: RatesFiltersService;
    @Inject(DocumentFiltersServiceS) documentFiltersService!: DocumentFiltersService;
    @Inject(CompsetsServiceS) compsetsService!: CompsetsService;
    @Inject(HotelsServiceS) protected hotelsService!: HotelsService;
    @Inject(UserServiceS) protected userService!: UserService;

    private disabledChart: any = [];

    tooltipDay: Day | null = null;
    tooltipFocusElement: HTMLElement | null = null;

    get chartData(): { labels: (Day | string)[][], datasets: ChartDataSets[] } | null {
        const datasets: ChartDataSets[] = [];
        let maxPrice = 0;

        if (!this.documentFiltersService.days || !this.compsetsService.competitors || !this.userService.currentHotelId) {
            return null;
        }
        const competitors = this.ratesFiltersService.competitors || [];
        const hotels = [...this.compsetsService.competitors].filter(item => competitors.includes(item)).sort((a, b) => a - b);
        hotels.unshift(this.userService.currentHotelId);
        this.listOfGraphData(this.userService.currentHotelId);
        hotels.forEach(hotelId => {
            const data: (number | null)[] = [];

            this.documentFiltersService.days.forEach(day => {
                const room = this.ratesService.getRoom(day, hotelId);

                if (room) {
                    data.push(this.ratesService.switchPrice(room) || null); // Null needs to show Sold Out on the Graph (CI-2255)
                } else {
                    data.push(null);
                }
            });

            this.listOfGraphData(hotelId);

            datasets.push({
                label: String(hotelId),
                data,
                borderColor: this.borderColor(hotelId),
                borderWidth: hotelId === this.userService.currentHotelId ? 3 : 2,
                lineTension: 0,
                borderJoinStyle: 'round',
                hidden: this.disabledChart.find(({ id }: IDisabled) => id === Number(hotelId))
                    ? this.disabledChart.find(({ id }: IDisabled) => id === Number(hotelId)).val
                    : false,
            });

            const prices = data.filter(price => price !== null) as number[];
            const maxHotelPrice = Math.max(...(prices.length ? prices : [0]));

            if (maxPrice < maxHotelPrice) {
                maxPrice = maxHotelPrice;
            }
        });
        this.$emit('calculate-prices', { maxPrice });

        const { year, month } = this.documentFiltersService;

        const labels = this.documentFiltersService.days.map(day => [day, this.$t(`dayShort.${new Date(year, month, day).getDay()}`).toString()]);

        return {
            labels,
            datasets,
        };
    }

    get options(): ChartOptions {
        return {
            maintainAspectRatio: false,
            elements: {
                line: {
                    backgroundColor: 'rgba(255, 255, 255, 0.1)',
                    tension: 0,
                },
                point: {
                    radius: 3,
                    backgroundColor: '#ECF1F5',
                },
            },
            scales: {
                xAxes: [{
                    gridLines: {
                        display: true,
                        borderDash: [0, 1],
                        offsetGridLines: true,
                        color: '#ECF1F5',
                    },
                }],
                yAxes: [{
                    gridLines: {
                        display: true,
                        offsetGridLines: true,
                        borderDash: [0, 4],
                        color: '#ECF1F5',
                        zeroLineWidth: 0,
                    },
                    ticks: {
                        autoSkip: true,
                        padding: 10,
                    },
                    offset: true,
                }],
            },
            legend: {
                display: false,
            },
            plugins: {
                filler: {
                    propagate: true,
                },
            },
        };
    }

    parseLabel(label: string | null) {
        return label ? String(parseInt(label, 10)) : null;
    }

    setCurrentDay(day: string | null) {
        this.$emit('current-day', this.parseLabel(day));
        this.tooltipDay = this.parseLabel(day) as unknown as Day;
        this.tooltipDay = this.tooltipDay && +this.tooltipDay as Day;
    }

    updated() {
        setScrollbarPadding();
    }

    onTooltipClick(dayLabel: string) {
        const hotelId = String(this.userService.currentHotelId);
        const day = String(this.parseLabel(dayLabel));

        if (hotelId) {
            this.$router.push({
                name: `${this.$route.name}.day-rate`,
                params: { hotelId, day },
            });
        }
    }

    borderColor(hotelId: number) {
        const { currentHotelId } = this.userService;
        return currentHotelId && hotelId === currentHotelId ? CURRENT_HOTEL_GRAPH_COLOR : this.colorByHotel(hotelId);
    }

    colorByHotel(hotelId: number) {
        return this.hotelsService.getHotelsGraphColor()[hotelId];
    }

    toggleGraphData(hotelId: number) {
        const i = this.disabledChart[this.disabledChart.findIndex((el: IDisabled) => el.id === hotelId)];
        i.val = !i.val;
    }

    listOfGraphData(hotelId: number) {
        const i = this.disabledChart[this.disabledChart.findIndex((el: IDisabled) => el.id === hotelId)];
        if (i === undefined) {
            this.disabledChart.push({ id: hotelId, val: false });
        }
    }

    resetGraph() {
        this.disabledChart = [];
    }

    setTooltipElement(el: HTMLElement) {
        this.tooltipFocusElement = el;
    }
}
