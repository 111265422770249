


































import { Inject } from 'inversify-props';
import { Component, Vue } from 'vue-property-decorator';
import RatesGraphHotels from '@/modules/rates/components/graph/rates-graph-hotels.vue';
import DateDocumentFilter from '@/modules/document-filters/components/date-document-filter.vue';
import RatesActions from '@/modules/rates/components/actions/rates-actions.vue';
import RatesGraphTypeChanger from '@/modules/rates/components/graph/rates-graph-type-changer.vue';
import PageWrapper from '@/modules/common/components/page-wrapper.vue';
import RatesHeader from '@/modules/rates/components/rates-header.vue';
import TogglePrices from '@/modules/common/modules/rates/components/toggle-prices.vue';
import RatesService, { RatesServiceS } from '@/modules/rates/rates.service';
import DocumentFiltersService, { DocumentFiltersServiceS } from '@/modules/document-filters/document-filters.service';
import Day from '@/modules/common/types/day.type';
import RatesGraphDemand from '@/modules/rates/components/rates-graph-demand.vue';

@Component({
    components: {
        DateDocumentFilter,
        RatesActions,
        RatesGraphHotels,
        RatesGraphTypeChanger,
        PageWrapper,
        RatesHeader,
        TogglePrices,
        RatesGraphDemand,
    },
})
export default class extends Vue {
    @Inject(RatesServiceS) ratesService!: RatesService;
    @Inject(DocumentFiltersServiceS) private documentFiltersService!: DocumentFiltersService;

    day: Day | null = null;
    demandGraphOffset: number | null = null;

    selectedDay(day: Day | null) {
        this.day = day;
    }

    setDemandGraphOffset(prices: { maxPrice: number | null}) {
        const { maxPrice } = prices;
        const symbolWidth = 7.5;
        this.demandGraphOffset = maxPrice ? String(maxPrice).length * symbolWidth : 0;
    }

    get isNonSupportedModal() {
        return this.$route.name!.includes('.not-supported');
    }

    get demandAndOccupancy() {
        if (!this.documentFiltersService.days) {
            return null;
        }
        const demandAndOccupancyArray: {demand: (number | null), occupancy: (number | null)}[] = [];
        this.documentFiltersService.days.forEach(day => {
            if (this.ratesService.isNoData(day)) {
                demandAndOccupancyArray.push({ demand: null, occupancy: null });
            } else {
                const demandValue = this.ratesService.getDemand(day);
                const occupancyValue = this.ratesService.getOccupancy(day);
                demandAndOccupancyArray.push({ demand: demandValue, occupancy: occupancyValue });
            }
        });
        return demandAndOccupancyArray;
    }
}
