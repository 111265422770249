






























import { Component, Vue } from 'vue-property-decorator';
import { Inject } from 'inversify-props';
import FEATURES from '@/modules/common/constants/features.constant';
import DateDocumentFilter from '@/modules/document-filters/components/date-document-filter.vue';
import UserService, { UserServiceS } from '@/modules/user/user.service';
import HotelsService, { HotelsServiceS } from '@/modules/hotels/hotels.service';
import ClusterRatesService, { ClusterRatesServiceS } from '@/modules/cluster/cluster-rates.service';
import RatesHintTooltip from '@/modules/rates/components/rates-hint-tooltip.vue';
import CurrencySwitcher from '@/modules/common/components/currency-switcher.vue';
import CurrencySwitcherTooltip from '@/modules/common/components/currency-switcher-tooltip.vue';
import RatesScan from './rates-scan.vue';

@Component({
    components: {
        DateDocumentFilter,
        RatesScan,
        RatesHintTooltip,
        CurrencySwitcher,
        CurrencySwitcherTooltip,
    },
})
export default class RatesHeader extends Vue {
    @Inject(UserServiceS) private userService!: UserService;
    @Inject(HotelsServiceS) private hotelsService!: HotelsService;
    @Inject(ClusterRatesServiceS) clusterRatesService!: ClusterRatesService;

    infoTooltip: boolean = false;

    get isHotelUser() {
        return this.$route.path.split('/')[1] === 'hotel';
    }

    get hotelName(): string | null {
        const { currentHotelId = +this.$route.params.hotelId } = this.userService;

        if (!currentHotelId) {
            return null;
        }

        return this.hotelsService.getHotelName(currentHotelId);
    }

    get backRoute() {
        const branch = this.$route.name!.split('.')[0];
        const isAnyCluster = this.$route.params.value === 'any';

        switch (branch) {
            case 'chain':
                return !isAnyCluster
                    ? {
                        name: 'chain.rates',
                        params: this.$route.params,
                    }
                    : {
                        name: 'chain.rates',
                        params: this.$route.params,
                    };

            case 'cluster':
                return {
                    name: 'cluster.rates',
                };

            default: return {};
        }
    }

    get feature() {
        return FEATURES.RATE;
    }
}
