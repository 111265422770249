

































import { Component, Vue, Prop } from 'vue-property-decorator';
import { Inject } from 'inversify-props';
import CURRENT_HOTEL_GRAPH_COLOR from '@/modules/common/constants/current-hotel-graph-color.constant';
import GraphLegendIcon from '@/modules/common/components/ui-kit/graph-legend-icon.vue';
import HotelsService, { HotelsServiceS } from '@/modules/hotels/hotels.service';
import UserService, { UserServiceS } from '@/modules/user/user.service';
import CompsetsService, { CompsetsServiceS } from '@/modules/compsets/compsets.service';
import RatesFiltersService, { RatesFiltersServiceS } from '@/modules/rates/rates-filters.service';

export interface IHotelLabel {
    name: string | null;
    color: string;
    hotelId: number;
    disabled: boolean;
}
export interface IDisabled {
    val: boolean;
    id: number;
}

@Component({
    components: {
        GraphLegendIcon,
    },
})
export default class GraphHotelsLabel extends Vue {
    @Inject(HotelsServiceS) private hotelsService!: HotelsService;
    @Inject(UserServiceS) private userService!: UserService;
    @Inject(CompsetsServiceS) compsetsService!: CompsetsService;
    @Inject(RatesFiltersServiceS) private ratesFiltersService!: RatesFiltersService;

    @Prop({
        type: Boolean,
        default: true,
    })
    reset!: boolean;

    disableCurrentHotel: boolean = false;
    disabledCompetitors: any = [];

    beforeUpdate() {
        if (this.reset && (this.disableCurrentHotel || this.disabledCompetitors.length)) {
            this.resetGraph();
        }
    }

    get hotelsGraphColor() {
        return this.hotelsService.getHotelsGraphColor();
    }

    get currentHotel(): IHotelLabel | null {
        const { currentHotelId } = this.userService;

        if (!currentHotelId) {
            return null;
        }

        return {
            name: this.hotelsService.getHotelName(currentHotelId),
            color: this.disableCurrentHotel ? 'GREY' : CURRENT_HOTEL_GRAPH_COLOR,
            hotelId: currentHotelId,
            disabled: this.disableCurrentHotel,
        };
    }

    get competitors(): IHotelLabel[] | null {
        const competitorsFilters = this.ratesFiltersService.competitors || [];

        return this.hotelsGraphColor && Object.keys(this.hotelsGraphColor).map(hotelId => ({
            name: this.hotelsService.getHotelName(Number(hotelId)),
            color: (this.disabledCompetitors.find(({ id }: IDisabled) => id === Number(hotelId))
                ? this.disabledCompetitors.find(({ id }: IDisabled) => id === Number(hotelId)).val
                : false)
                ? 'GREY'
                : this.hotelsGraphColor[hotelId],
            hotelId: Number(hotelId),
            disabled: this.disabledCompetitors.find(({ id }: IDisabled) => id === Number(hotelId))
                ? this.disabledCompetitors.find(({ id }: IDisabled) => id === Number(hotelId)).val
                : false,
        })).sort((a, b) => a.hotelId - b.hotelId).filter(item => competitorsFilters.includes(item.hotelId));
    }

    toggleDisableCurrentHotel() {
        this.disableCurrentHotel = !this.disableCurrentHotel;
    }

    toggleDisableCompetitors(hotelId: number) {
        const i = this.disabledCompetitors[this.disabledCompetitors.findIndex((el: IDisabled) => el.id === hotelId)];
        if (i === undefined) {
            this.disabledCompetitors.push({ id: hotelId, val: true });
        } else {
            i.val = !i.val;
        }
    }

    toggleGraphLines(id: number) {
        this.$emit('toggleGraphData', id);
    }

    resetGraph() {
        this.disableCurrentHotel = false;
        this.disabledCompetitors = [];
        this.$emit('resetGraph');
    }
}
