import { Inject } from 'inversify-props';
import { Component, Vue } from 'vue-property-decorator';
import DocumentFiltersService, { DocumentFiltersServiceS } from '@/modules/document-filters/document-filters.service';
import CompsetsService, { CompsetsServiceS } from '@/modules/compsets/compsets.service';
import CompsetModel from '@/modules/compsets/models/compset.model';
import Item from '@/modules/common/interfaces/item.interface';
import HotelsService, { HotelsServiceS } from '@/modules/hotels/hotels.service';
import ProvidersService, { ProvidersServiceS } from '@/modules/providers/providers.service';
import ScanDisabledProviders from '@/modules/common/modules/rates/constants/scan-disabled-providers.enum';
import RoomTypesService, { RoomTypesServiceS } from '@/modules/room-types/room-types.service';
import PRICE_TYPE from '@/modules/document-filters/constants/price-type.constant';
import { $enum } from 'ts-enum-util';
import MealTypeModel from '@/modules/meal-types/models/meal-type.model';
import MealTypesService, { MealTypesServiceS } from '@/modules/meal-types/meal-types.service';
import ANY_ROOM_TYPE from '@/modules/room-types/constants/any-room-type.constant';
import RoomTypeModel from '@/modules/room-types/models/room-type.model';
import RatesService, { RatesServiceS } from '../rates.service';
import RatesFiltersService, { RatesFiltersServiceS } from '../rates-filters.service';

@Component
export default class RatesFilterItemsMixin extends Vue {
    @Inject(DocumentFiltersServiceS)
    protected documentFiltersService!: DocumentFiltersService;

    @Inject(RatesFiltersServiceS)
    protected ratesFiltersService!: RatesFiltersService;

    @Inject(RatesServiceS)
    protected ratesService!: RatesService;

    @Inject(CompsetsServiceS)
    protected compsetsService!: CompsetsService;

    @Inject(HotelsServiceS)
    protected hotelsService!: HotelsService;

    @Inject(ProvidersServiceS)
    protected providersService!: ProvidersService;

    @Inject(RoomTypesServiceS)
    protected roomTypesService!: RoomTypesService;

    @Inject(MealTypesServiceS)
    protected mealTypesService!: MealTypesService;

    protected get losItems() {
        return this.documentFiltersService.losItems;
    }

    protected set los(value: number | null) {
        this.documentFiltersService.saveLos(value);
    }

    protected get los() {
        const { los } = this.documentFiltersService.storeState.settings;
        return los;
    }

    protected get compsetTypeItems(): Item[] {
        const { compsets } = this.compsetsService;

        if (!compsets) {
            return [];
        }

        return compsets.map((compset: CompsetModel) => ({
            value: compset.id,
            name: `${compset.name} (${this.$t(`filterSettings.compset.${compset.type}`)})`,
        }));
    }

    protected get compsetId() {
        return this.documentFiltersService.storeState.settings.compsetId;
    }

    protected set compsetId(value) {
        if (value) {
            this.documentFiltersService.updateCompset(value);
        }
    }

    protected get competitorItems() {
        const { competitors } = this.compsetsService;
        if (!competitors) {
            return [];
        }
        return competitors.map(val => ({
            value: val,
            name: this.hotelsService.getHotelName(val),
        }));
    }

    protected get competitors() {
        const { competitors } = this.ratesFiltersService;

        if (!competitors) {
            return [];
        }

        return competitors.map(item => ({
            name: this.hotelsService.getHotelName(item),
            value: item,
        })) || [];
    }

    protected set competitors(value) {
        this.ratesFiltersService.competitors = value.map(item => item.value);
    }

    protected get providerItems(): Item[] {
        const routeName = this.$route.name || '';
        const { currentCompset } = this.compsetsService;
        const isAnalysisMode = routeName.includes('analysis');
        const isCalendarView = false
            || routeName.includes('table')
            || routeName.includes('graph');

        if (!currentCompset) {
            return [];
        }

        const providerList = Array
            .from(new Set(currentCompset.rateProviders));

        if (isAnalysisMode) {
            return providerList
                .map(provider => ({
                    value: provider,
                    name: this.getProviderLabel(provider),
                    disabled: provider === ScanDisabledProviders.ALL,
                }));
        }

        // NOTE: Show all channes only on calendar view
        if (!isCalendarView) {
            return providerList
                .map(provider => ({
                    value: provider,
                    name: this.getProviderLabel(provider),
                }));
        }

        return providerList
            .map(provider => ({
                value: provider,
                name: this.getProviderLabel(provider),
            }))
            .filter(val => val.value !== ScanDisabledProviders.ALL);
    }

    protected get provider() {
        if (this.compsetsService.currentCompset
            && this.ratesFiltersService.currentProvider
            && !this.compsetsService.currentCompset.rateProviders.includes(this.ratesFiltersService.currentProvider)
            && this.ratesFiltersService.currentProvider !== ScanDisabledProviders.ALL
        ) {
            return this.compsetsService.currentCompset.rateProviders[0];
        }

        return this.ratesFiltersService.currentProvider;
    }

    protected set provider(value) {
        this.ratesFiltersService.saveProvider(value);
    }

    protected get posItems(): Item[] {
        const { posRatesItems, posMarketItems } = this.documentFiltersService;
        const isRatesPage = this.$route.name ? this.$route.name.includes('rate') : false;
        return isRatesPage ? posRatesItems : posMarketItems;
    }

    protected get pos() {
        return this.documentFiltersService.storeState.settings.pos;
    }

    protected set pos(value) {
        this.documentFiltersService.savePos(value);
    }

    protected get priceTypeItems(): Item[] {
        return $enum(PRICE_TYPE).map((value): Item => ({
            value,
            name: this.$t(`filterSettings.price.${value}`) as string,
        }));
    }

    protected get priceType() {
        return this.ratesFiltersService.priceType;
    }

    protected set priceType(value) {
        this.ratesFiltersService.priceType = value;
    }

    protected get numberOfGuestsItems(): Item[] {
        return [1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map(item => ({
            name: String(item),
            value: item,
        }));
    }

    protected get numberOfGuests() {
        return this.ratesFiltersService.currentNumberOfGuests;
    }

    protected set numberOfGuests(value) {
        this.ratesFiltersService.saveNumberOfGuests(Number(value));
    }

    protected get roomTypeItems(): Item[] {
        if (!this.roomTypesService.rooms) {
            return [{
                value: ANY_ROOM_TYPE.id,
                name: ANY_ROOM_TYPE.name,
            }];
        }

        return this.roomTypesService.rooms
            .filter((roomType: RoomTypeModel) => !!roomType.name)
            .map((roomType: RoomTypeModel) => ({
                value: roomType.id,
                name: roomType.name,
            }));
    }

    get roomType() {
        return this.ratesService.storeState.settings.roomTypeId;
    }

    set roomType(value) {
        if (value) {
            this.ratesService.storeState.settings.roomTypeId = value;
        }
    }

    get mealTypeItems(): Item[] {
        const { mealTypes } = this.mealTypesService;
        if (!mealTypes) {
            return [];
        }
        return mealTypes.map((mealType: MealTypeModel) => ({
            value: mealType.id,
            name: mealType.displayName,
        }));
    }

    get mealType() {
        return this.ratesService.storeState.settings.mealTypeId;
    }

    set mealType(value) {
        if (value !== null) {
            this.ratesService.storeState.settings.mealTypeId = value;
        }
    }

    protected isFilterDisabled = this.ratesFiltersService.isFilterDisabled.bind(this.ratesFiltersService);

    private getProviderLabel(providerName: string) {
        const { data } = this.providersService;

        if (!data) {
            return providerName;
        }

        const provider = data
            .find(currentProvider => currentProvider.name === providerName);

        if (!provider) {
            return '';
        }

        return provider.label || provider.name || '';
    }
}
