import getScrollbarWidth from '@/modules/common/filters/scrollbar-width.filter';

export default function setScrollbarPadding() {
    const elements = document.getElementsByTagName('html');
    const el = elements.item(0);
    const scrollWidth = getScrollbarWidth();
    if (el) {
        if (el.scrollHeight === el.clientHeight) {
            el.style.paddingRight = `${scrollWidth}px`;
        } else {
            el.style.paddingRight = '0px';
        }
    }
}
