






















import { Component, Vue, Prop } from 'vue-property-decorator';
import Demand from '@/modules/common/components/ui-kit/demand.vue';
import Occupancy from '@/modules/occupancy/components/occupancy.vue';
import Day from '@/modules/common/types/day.type';

@Component({
    components: {
        Demand,
        Occupancy,
    },
})
export default class RatesGraphDemand extends Vue {
    @Prop({
        type: Array,
    })
    dayStatistics!: {demand: (number | null), occupancy: (number | null)}[];

    @Prop({
        type: Number,
        default: 40,
    })
    total!: number;

    @Prop({
        type: String,
    })
    selectedDay!: Day;

    @Prop({
        type: Number,
        required: false,
        default: 0,
    })
    leftOffset!: number;

    @Prop({
        type: Object,
        default: () => ({}),
    })
    itemWrapperStyle!: Record<string, any>;

    get statistic() {
        return this.dayStatistics;
    }
}
