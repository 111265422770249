
import { Component } from 'vue-property-decorator';
import { Inject } from 'inversify-props';

import HotelsService, { HotelsServiceS } from '@/modules/hotels/hotels.service';
import CURRENT_HOTEL_GRAPH_COLOR from '@/modules/common/constants/current-hotel-graph-color.constant';

import { FirstColumnType, TableRow } from '../../interfaces/day-rate-tooltip.interface';
import DayRateTooltipCompset from './day-rate-tooltip-compset.vue';

@Component({
    extends: DayRateTooltipCompset,
})
export default class DayRateTooltipHotels extends DayRateTooltipCompset {
    @Inject(HotelsServiceS)
    private hotelsService!: HotelsService;

    firstColumns = [FirstColumnType.LEGEND, FirstColumnType.RANK];

    mutateRoomData(roomData: TableRow[]) {
        roomData.forEach((_, index) => {
            const room = roomData[index];
            const hotelColor = this.hotelsService
                .getHotelsGraphColor()[room.hotelId];

            room.legendColor = room.hotelId !== this.hotelId
                ? hotelColor
                : CURRENT_HOTEL_GRAPH_COLOR;
        });

        return roomData;
    }
}
