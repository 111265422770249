











import MealTypesService, { MealTypesServiceS } from '@/modules/meal-types/meal-types.service';
import { Component, Vue, Prop } from 'vue-property-decorator';
import { Inject } from 'inversify-props';
// @ts-ignore
import CustomSelect, { Item } from '@/modules/common/components/ui-kit/custom-select.vue';
import MealTypeModel from '@/modules/meal-types/models/meal-type.model';
import RatesService, { RatesServiceS } from '@/modules/rates/rates.service';
import UserService, { UserServiceS } from '@/modules/user/user.service';
import ClusterService, { ClusterServiceS } from '@/modules/cluster/cluster.service';

@Component({
    components: { CustomSelect },
})
export default class MealTypeFilter extends Vue {
    @Inject(MealTypesServiceS) mealTypesService!: MealTypesService;
    @Inject(RatesServiceS) ratesService!: RatesService;
    @Inject(UserServiceS) userService!: UserService;
    @Inject(ClusterServiceS) clusterService!: ClusterService;

    @Prop({
        required: false,
        type: Boolean,
        default: false,
    })
    disable!: boolean;

    get mealType() {
        return this.ratesService.storeState.settings.mealTypeId;
    }

    set mealType(value) {
        if (value !== null) {
            this.ratesService.storeState.settings.mealTypeId = value;
        }
    }

    currentMealType(mealType: number) {
        const mealTypeArr = this.mealTypesService.getMealType(mealType);
        if (!mealTypeArr) {
            return 'Any';
        }
        return mealTypeArr.displayName;
    }

    get mealTypeItems(): Item[] {
        const { mealTypes } = this.mealTypesService;
        if (!mealTypes) {
            return [];
        }
        return mealTypes.map((mealType: MealTypeModel) => ({
            value: mealType.id,
            name: mealType.displayName,
        }));
    }
}
