











import { Component, Vue, Prop } from 'vue-property-decorator';
import { Inject } from 'inversify-props';
// @ts-ignore
import CustomSelect, { Item } from '@/modules/common/components/ui-kit/custom-select.vue';
import CompsetsService, { CompsetsServiceS } from '@/modules/compsets/compsets.service';
import RatesFiltersService, { RatesFiltersServiceS } from '@/modules/rates/rates-filters.service';
import RatesService, { RatesServiceS } from '@/modules/rates/rates.service';

@Component({
    components: { CustomSelect },
})
export default class ProviderTypeFilter extends Vue {
    @Inject(CompsetsServiceS) private compsetsService!: CompsetsService;
    @Inject(RatesFiltersServiceS) private ratesFiltersService!: RatesFiltersService;
    @Inject(RatesServiceS) private ratesService!: RatesService;

    @Prop({
        required: false,
        type: Boolean,
        default: false,
    })
    disable!: boolean;

    get numberOfGuestsItems(): Item[] {
        return [1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map(item => ({
            name: String(item),
            value: item,
        }));
    }

    get numberOfGuests() {
        return this.ratesFiltersService.currentNumberOfGuests;
    }

    set numberOfGuests(value) {
        this.ratesFiltersService.saveNumberOfGuests(Number(value));
    }
}
