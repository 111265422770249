






































import { Component, Vue, Prop } from 'vue-property-decorator';

@Component
export default class CustomSwitch extends Vue {
    @Prop({ required: true, type: Boolean })
    value!: boolean;

    @Prop({ required: false, type: Boolean, default: false })
    isDisabled!: boolean;

    @Prop({ required: false, type: String })
    activeText!: string;

    @Prop({ required: false, type: String })
    inactiveText!: string;

    @Prop({ type: String })
    appearance!: string;

    handleChange(checked: boolean) {
        this.$emit('change', checked);
        this.$emit('input', checked);
    }
}
