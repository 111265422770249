let scrollbarWidth: number;

export default function getScrollbarWidth() {
    if (scrollbarWidth !== undefined) { return scrollbarWidth; }

    const outer = document.createElement('div');
    outer.style.visibility = 'hidden';
    outer.style.overflow = 'scroll';

    // @ts-ignore
    outer.style.msOverflowStyle = 'scrollbar';
    document.body.appendChild(outer);

    const inner = document.createElement('div');
    outer.appendChild(inner);

    scrollbarWidth = (outer.offsetWidth - inner.offsetWidth);
    outer.parentNode!.removeChild(outer);
    return scrollbarWidth;
}
