










import { Component, Vue } from 'vue-property-decorator';
import { Inject } from 'inversify-props';
import ScanBtn from '@/modules/common/components/ui-kit/scan-btn.vue';
import DocumentFiltersService, { DocumentFiltersServiceS } from '@/modules/document-filters/document-filters.service';
import UserService, { UserServiceS } from '@/modules/user/user.service';
import RatesService, { RatesServiceS } from '../rates.service';
import SCAN_STATUS from '../constants/scan-status.constant';

@Component({ components: { ScanBtn } })
export default class RatesScan extends Vue {
    @Inject(RatesServiceS) private ratesService!: RatesService;
    @Inject(DocumentFiltersServiceS) private documentFiltersService!: DocumentFiltersService;
    @Inject(UserServiceS) private userService!: UserService;

    get isScanning() {
        return this.ratesService.scanStatus === SCAN_STATUS.IN_PROGRESS;
    }

    get disabled() {
        return !this.ratesService.isScanAvailable();
    }

    get lastScanDate() {
        return this.ratesService.finishScanDate;
    }

    get title() {
        return this.disabled && this.documentFiltersService.isPreviousMonth ? 'Not allowed to scan previous months' : '';
    }

    async handleClick() {
        if (this.isScanning || this.disabled) {
            return;
        }
        this.$gtag.event('on-demand', {
            chainId: this.userService.chainId,
            userId: this.userService.id,
        });
        await this.ratesService.triggerScanNew();
    }
}
