










import { Component, Vue, Prop } from 'vue-property-decorator';
import { Inject } from 'inversify-props';
// @ts-ignore
import CustomSelect, { Item } from '@/modules/common/components/ui-kit/custom-select.vue';
import CompsetsService, { CompsetsServiceS } from '@/modules/compsets/compsets.service';
import RatesFiltersService, { RatesFiltersServiceS } from '@/modules/rates/rates-filters.service';
import ProvidersService, { ProvidersServiceS } from '@/modules/providers/providers.service';
import ScanDisabledProviders from '@/modules/common/modules/rates/constants/scan-disabled-providers.enum';

@Component({
    components: { CustomSelect },
})
export default class ProviderTypeFilter extends Vue {
    @Inject(CompsetsServiceS) private compsetsService!: CompsetsService;
    @Inject(RatesFiltersServiceS) private ratesFiltersService!: RatesFiltersService;
    @Inject(ProvidersServiceS) private providersService!: ProvidersService;

    get provider() {
        if (this.compsetsService.currentCompset
            && this.ratesFiltersService.currentProvider
            && !this.compsetsService.currentCompset.rateProviders.includes(this.ratesFiltersService.currentProvider)
            && this.ratesFiltersService.currentProvider !== ScanDisabledProviders.ALL
        ) {
            return this.compsetsService.currentCompset.rateProviders[0];
        }

        return this.ratesFiltersService.currentProvider;
    }
    set provider(value) {
        this.ratesFiltersService.saveProvider(value);
    }

    get currentPathName(): string {
        return this.$route.name!;
    }

    get providerItems(): Item[] {
        const { currentCompset } = this.compsetsService;
        const isAnalysisMode = this.currentPathName.includes('analysis');
        const isCalendarView = false
            || this.currentPathName.includes('table')
            || this.currentPathName.includes('graph');

        if (!currentCompset) {
            return [];
        }

        const providerList = Array
            .from(new Set(currentCompset.rateProviders));

        if (isAnalysisMode) {
            return providerList
                .map(provider => ({
                    value: provider,
                    name: this.getProviderLabel(provider),
                    disabled: provider === ScanDisabledProviders.ALL,
                }));
        }

        // NOTE: Show all channes only on calendar view
        if (!isCalendarView) {
            return providerList
                .map(provider => ({
                    value: provider,
                    name: this.getProviderLabel(provider),
                }));
        }

        return providerList
            .map(provider => ({
                value: provider,
                name: this.getProviderLabel(provider),
            }))
            .filter(val => val.value !== ScanDisabledProviders.ALL);
    }

    getProviderLabel(providerName: string) {
        const { data } = this.providersService;

        if (!data) {
            return providerName;
        }

        const provider = data
            .find(currentProvider => currentProvider.name === providerName);

        if (!provider) {
            return '';
        }

        return provider.label || provider.name || '';
    }
}
