
import { Component } from 'vue-property-decorator';
import CustomTooltip from '@/modules/common/components/ui-kit/custom-tooltip.vue';
import { TooltipSide } from '@/modules/common/components/ui-kit/day-tooltip-template.vue';
import DayRateTooltip from '../day-rate-tooltip.vue';
import { FirstColumnType } from '../../interfaces/day-rate-tooltip.interface';

const ARROW_SIZE = 12;
const OFFSET_X = 30;

@Component({
    extends: DayRateTooltip,
})
export default class DayRateTooltipCompset extends DayRateTooltip {
    firstColumns = [FirstColumnType.RANK];

    async updateTooltipPosition() {
        // NOTE: A hack to get actual position of graph's tooltip,
        //       since the initial position is 0,0
        await new Promise(r => setTimeout(r));

        if (!this.focusElement) {
            return;
        }

        const { tooltip } = this.$refs as unknown as { tooltip: CustomTooltip };
        const container = tooltip.getContainer();

        const {
            x, y, width, height,
        } = this.focusElement.getBoundingClientRect() as DOMRect;
        const sideBreakpoint = container.offsetWidth;

        this.tooltipSide = (x - OFFSET_X) - sideBreakpoint < 60
            ? TooltipSide.RIGHT : TooltipSide.LEFT;

        const offsetValue = OFFSET_X * (this.tooltipSide === 'right' ? 1 : -1);

        const left = `${x + offsetValue}px`;
        const top = `${y}px`;

        this.tooltipVerticalOffset = (container.offsetHeight / 2) - ARROW_SIZE;

        this.coords = {
            zIndex: 100,
            position: 'fixed',
            pointerEvents: 'none',
            left,
            top,
            width: `${width}px`,
            height: `${height}px`,
        };
    }
}
