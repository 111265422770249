import { render, staticRenderFns } from "./excel-btn.vue?vue&type=template&id=74206780&"
import script from "./excel-btn.vue?vue&type=script&lang=ts&"
export * from "./excel-btn.vue?vue&type=script&lang=ts&"
import style0 from "./excel-btn.vue?vue&type=style&index=0&id=74206780&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports