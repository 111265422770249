











import { Component, Vue, Prop } from 'vue-property-decorator';
import { $enum } from 'ts-enum-util';
import { Inject } from 'inversify-props';
// @ts-ignore
import CustomSelect, { Item } from '@/modules/common/components/ui-kit/custom-select.vue';
import RatesFiltersService, { RatesFiltersServiceS } from '@/modules/rates/rates-filters.service';
import UserService, { UserServiceS } from '@/modules/user/user.service';
import ClusterService, { ClusterServiceS } from '@/modules/cluster/cluster.service';
import PRICE_TYPE from '../../../document-filters/constants/price-type.constant';

@Component({
    components: { CustomSelect },
})
export default class PriceTypeFilter extends Vue {
    @Inject(RatesFiltersServiceS) ratesFiltersService!: RatesFiltersService;
    @Inject(UserServiceS) userService!: UserService;
    @Inject(ClusterServiceS) clusterService!: ClusterService;

    @Prop({
        required: false,
        type: Boolean,
        default: false,
    })
    disable!: boolean;

    get priceType() {
        return this.ratesFiltersService.priceType;
    }

    set priceType(value) {
        this.ratesFiltersService.priceType = value;
    }

    get priceTypeItems(): Item[] {
        return $enum(PRICE_TYPE).map((value): Item => ({
            value,
            name: this.$t(`filterSettings.price.${value}`) as string,
        }));
    }
}
